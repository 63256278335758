import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from './images/logo-coowise.svg';
import emailFooter from './images/emailFooter.svg';
import markerFooter from './images/markerFooter.svg';
import MP_Black from './images/MicrosoftPartner/MP_Black.png';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.onClickLink = this.onClickLink.bind(this);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Footer");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Footer", page: '/Footer' });
    }

    onClickLink(id) {
        let element = document.getElementById(id);
        if (element != null)
            element.scrollIntoView();
    }

    render() {
        return (
            <footer id="footer" className="section-footer" onMouseOver={this.props.currentSection != "Footer" ? this.changeSection : () => { }}>
                <Container>
                    <Row className='footer-row'>
                        <Col xs={12} lg={3} className='col-images'>
                            <img src={logo} alt="logo-coowise" class='logo-coowise'/>
                            <img src={MP_Black} alt="microsoft-partner-logo" class='ms-partner'/>
                        </Col>
                        <Col xs={12} lg={3} className="footer-col">
                            <h4 class="title-footer cursor" onClick={() => this.onClickLink("quienesSomos")}>{this.props.language == "ES" ? "QUIENES SOMOS" : "ABOUT US"}</h4>
                            <div class="div-servicio-footer aboutus">
                                <img src={emailFooter} />
                                <span class="description-contact-footer"><a href="mailto:contact@coowise.com">contact@coowise.com</a></span>
                            </div>
                            <div class="div-servicio-footer aboutus">
                                <img src={markerFooter} />
                                <span class="description-contact-footer"><a href={"https://www.google.com/maps/place/P%C3%A4rnu+mnt.+139c,+11317+Tallinn,+Estonia/@59.4069893,24.731644,17z/data=!3m1!4b1!4m6!3m5!1s0x469294c3c45ab373:0xd01cdf63dfe36c23!8m2!3d59.4069866!4d24.7342189!16s%2Fg%2F11cs7ghdxx?entry=ttu"} target="_blank">Pärnu mnt 139c, Tallinn, Estonia, 11317</a></span>
                            </div>
                        </Col>
                        <Col xs={12} lg={3} className="footer-col">
                            <h4 class="title-footer cursor" onClick={() => this.onClickLink("servicios")}>{this.props.language == "ES" ? "SERVICIOS" : "SERVICES"}</h4>
                            <div class="div-servicio-footer">
                                <a class="title-servicio-footer notlegal">{this.props.language == "ES" ? "Subcontrataciones" : "Outsourcing"}</a>
                            </div>
                            <div class="div-servicio-footer">
                                <a class="title-servicio-footer notlegal">{this.props.language == "ES" ? "Consultoría y desarrollo" : "Consultancy and development"}</a>
                            </div>
                        </Col>
                        <Col xs={12} lg={3} className="footer-col">
                            <h4 class="title-footer cursor">{this.props.language == "ES" ? "LEGAL" : "LEGAL"}</h4>
                            <div class="div-servicio-footer">
                                <a class="title-servicio-footer legal" href={window.location.origin + "/privacy-policy"} target="_blank" >{this.props.language == "ES" ? "Política de privacidad" : "Privacy policy"}</a>
                            </div>
                            <div class="div-servicio-footer">
                                <a class="title-servicio-footer legal" href={window.location.origin + "/cookies-policy"} target="_blank">{this.props.language == "ES" ? "Política de cookies" : "Cookie policy"}</a>
                            </div>
                        </Col>
                    </Row>
                    <Row className='footer-row'>
                        <Col className="col-copy-right">
                            <hr class="line-footer"></hr>
                            <span class="copy-right">© 2023 Coowise, All Rights Reserved.</span>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

Footer.propTypes = {
    language: PropTypes.string.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

export default Footer;